// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sk-galeria-js": () => import("./../../../src/pages/sk/galeria.js" /* webpackChunkName: "component---src-pages-sk-galeria-js" */),
  "component---src-pages-sk-index-js": () => import("./../../../src/pages/sk/index.js" /* webpackChunkName: "component---src-pages-sk-index-js" */),
  "component---src-pages-sk-kontakt-js": () => import("./../../../src/pages/sk/kontakt.js" /* webpackChunkName: "component---src-pages-sk-kontakt-js" */),
  "component---src-pages-sk-kosik-js": () => import("./../../../src/pages/sk/kosik.js" /* webpackChunkName: "component---src-pages-sk-kosik-js" */),
  "component---src-pages-sk-objednavka-js": () => import("./../../../src/pages/sk/objednavka.js" /* webpackChunkName: "component---src-pages-sk-objednavka-js" */),
  "component---src-pages-sk-produkty-js": () => import("./../../../src/pages/sk/produkty.js" /* webpackChunkName: "component---src-pages-sk-produkty-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-document-js": () => import("./../../../src/templates/document.js" /* webpackChunkName: "component---src-templates-document-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

